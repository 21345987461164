import React, { useRef, useState } from 'react'
import classNames from 'classnames'

import { useCustomer } from '../../../utils'
import FaqSection from './FaqSection'

export default function Faqs({ faqs, isFullWidth = false, ...props }) {
  const [visibleTopicIndex, setVisibleTopic] = useState(-1)
  const { isAboGroup = false } = useCustomer()
  const faqSectionRef = useRef(null)

  const filteredFaqs = faqs.filter((faq) => !(faq.aboUsersOnly && !isAboGroup))

  const handleClick = (index) => {
    setVisibleTopic(index)
    faqSectionRef?.current.scrollIntoView({
      behavior: 'smooth',
    })
  }

  const containerClasses = classNames('mt-3', {
    container: !isFullWidth,
  })

  const mobileRowClasses = classNames('row d-md-none', {
    'mx-0': isFullWidth,
  })
  const rowClasses = classNames('row', {
    'mx-0': isFullWidth,
  })

  const iconsContainerClasses = classNames(
    filteredFaqs.length > 1 ? 'col-6' : 'col-12',
    'text-center',
    'mb-3'
  )

  return (
    <section className={containerClasses}>
      {/*For Mobile device*/}
      <div className={mobileRowClasses}>
        {filteredFaqs.map((faq, index) => {
          const iconClasses = classNames(faq.icon, 'd-block text-primary mb-2')
          return (
            <div
              key={index}
              className={iconsContainerClasses}
              onClick={() => handleClick(index)}
            >
              <div className="border p-3 d-flex flex-column align-self-center justify-content-center cursor-pointer faq__topic-heading">
                <i className={iconClasses} />
                <span>{faq.heading}</span>
              </div>
            </div>
          )
        })}
      </div>
      {/*Used in Mobile and Desktop both*/}
      <div className={rowClasses} ref={faqSectionRef}>
        {filteredFaqs.map((faq, index, arr) => {
          return (
            <FaqSection
              key={index}
              isVisible={visibleTopicIndex === index}
              faq={faq}
              faqsLength={arr.length}
              {...props}
            />
          )
        })}
      </div>
    </section>
  )
}

export { default as faqsVariants } from './variants.js'
