import React, { useState } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import SlideDown from 'react-slidedown'

import RawHtml from '../RawHtml'
import Icon from '../Icon'
import { TranslationComponent } from '../../../utils'

export default function FaqSection({
  isVisible,
  faq,
  faqsLength,
  noAccordionIcon,
  lightAnswerBackground,
  questionAnswerColor,
  headingColor,
  iconColor,
  isCenterAligned,
}) {
  const {
    heading = <TranslationComponent text="WRITE_YOUR_TEXT_HERE" />,
    icon = 'cf-truck-o',
    items,
  } = faq
  const [visibleIndex, setVisibleIndex] = useState(-1)

  const containerClasses = classNames(
    'col-12 mb-4',
    faqsLength > 1 && `col-lg-${12 / Math.min(faqsLength, 3)}`,
    faqsLength > 1 && `col-md-${12 / Math.min(faqsLength, 2)}`,
    !isVisible && 'd-none d-md-block'
  )
  const boxClasses = classNames(
    'shadow-sm pt-4 px-3',
    faqsLength > 1 && 'faq__box'
  )
  const iconClasses = classNames(icon, iconColor, 'd-block mb-2')
  const rowClasses = classNames(
    'faq__box-row px-0',
    questionAnswerColor,
    isCenterAligned && 'flex-column'
  )
  const accordionClasses = classNames(
    'd-block mb-2 pr-4',
    isCenterAligned && 'text-center'
  )
  const questionClasses = classNames('py-2 faq__box-item')
  const answerClasses = classNames(
    'd-block px-3 py-2',
    questionAnswerColor,
    lightAnswerBackground && 'bg-primary-light',
    isCenterAligned && 'text-center'
  )

  return (
    <div className={containerClasses}>
      <div className={boxClasses}>
        <div className="text-center mb-3">
          <i className={iconClasses} />
          <h3 className={headingColor}>{heading}</h3>
        </div>
        {items.map(({ question, answer }, qaIndex) => {
          const isClosed = visibleIndex !== qaIndex
          const handleOpen = () =>
            setVisibleIndex(visibleIndex === qaIndex ? -1 : qaIndex)
          const accordionIconClasses = classNames(
            'mb-2',
            isClosed || 'faq__box-icon',
            isCenterAligned && 'align-self-end position-absolute'
          )

          return (
            <div key={qaIndex} className={questionClasses}>
              <button type="button" className={rowClasses} onClick={handleOpen}>
                <RawHtml className={accordionClasses} html={question} />
                {!noAccordionIcon && (
                  <Icon
                    className={accordionIconClasses}
                    symbol="cf-arrow-down"
                  />
                )}
              </button>
              <SlideDown closed={isClosed}>
                <RawHtml
                  className={answerClasses}
                  html={answer}
                  element="div"
                />
              </SlideDown>
            </div>
          )
        })}
      </div>
    </div>
  )
}

FaqSection.propTypes = {
  isVisible: PropTypes.bool,
  faq: PropTypes.shape({
    heading: PropTypes.string,
    icon: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        question: PropTypes.string,
        answer: PropTypes.string,
      })
    ),
  }),
  faqsLength: PropTypes.number,
  noAccordionIcon: PropTypes.bool,
  lightAnswerBackground: PropTypes.bool,
  questionAnswerColor: PropTypes.string,
  headingColor: PropTypes.string,
  iconColor: PropTypes.string,
}

FaqSection.defaultProps = {
  isVisible: false,
  faq: {
    heading: '',
    icon: '',
    items: [
      {
        question: '',
        answer: '',
      },
    ],
  },
  faqsLength: 0,
  noAccordionIcon: false,
  lightAnswerBackground: false,
  questionAnswerColor: 'text-dark',
  headingColor: 'text-dark',
  iconColor: 'text-primary-hover',
}
