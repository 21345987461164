export default (t) => [
  {
    name: t('PALI_VARIANT_DEFAULT'),
    props: {
      faqs: [
        {
          icon: 'cf-cart-o',
          heading: 'Orders',
          aboUsersOnly: false,
          items: [
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
          ],
        },
      ],
    },
  },
  {
    name: t('PALI_FAQS_TWO_ITEMS'),
    props: {
      faqs: [
        {
          icon: 'cf-cart-o',
          heading: t('PALI_SAMPLE_HEADING_SHORT'),
          aboUsersOnly: false,
          items: [
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
          ],
        },
        {
          icon: 'cf-truck-o',
          heading: t('PALI_SAMPLE_HEADING_SHORT'),
          aboUsersOnly: false,
          items: [
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
          ],
        },
      ],
      noAccordionIcon: true,
      questionAnswerColor: 'text-primary-hover',
    },
  },
  {
    name: t('PALI_FAQS_THREE_ITEMS'),
    props: {
      faqs: [
        {
          aboUsersOnly: false,
          items: [
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
          ],
        },
        {
          aboUsersOnly: false,
          items: [
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
          ],
        },
        {
          aboUsersOnly: false,
          items: [
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
          ],
        },
      ],
      lightAnswerBackground: true,
    },
  },
  {
    name: t('PALI_FAQS_FOUR_ITEMS'),
    props: {
      faqs: [
        {
          icon: 'cf-cart-o',
          heading: t('PALI_SAMPLE_HEADING_SHORT'),
          aboUsersOnly: false,
          items: [
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
          ],
        },
        {
          icon: 'cf-dog',
          heading: 'For Dogs',
          aboUsersOnly: false,
          items: [
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
          ],
        },
        {
          icon: 'cf-cat',
          heading: 'For Cats',
          aboUsersOnly: false,
          items: [
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
          ],
        },
        {
          icon: 'cf-truck-o',
          heading: t('PALI_SAMPLE_HEADING_SHORT'),
          aboUsersOnly: false,
          items: [
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
            {
              question: t('PALI_FAQS_QUESTION'),
              answer: t('PALI_FAQS_ANSWER'),
            },
          ],
        },
      ],
      iconColor: 'text-dark',
      questionAnswerColor: 'text-logo-color',
      headingColor: 'text-primary-hover',
    },
  },
]
